import Typography from 'typography';

const typography = new Typography({
  baseFontSize: '16px',
  baseLineHeight: 1.45,
  headerFontFamily: ['Programme', '-apple-system', 'sans-serif'],
  bodyFontFamily: ['Programme', '-apple-system', 'sans-serif'],
});

export default typography;
